import { Routes } from "@angular/router";

import { IndexComponent } from "./index/index.component";
import { HomeComponent } from "./home/home.component";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FaqComponent } from "./faq/faq.component";
import { BeforeLoginService } from "./Services/before-login.service";
import { AfterLoginService } from "./Services/after-login.service";
import { RateComparisionComponent } from "./rate-comparision/rate-comparision.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AgentLocationsComponent } from "./agent-locations/agent-locations.component";
import { CampaignUnsubscribeComponent } from "./campaign-unsubscribe/campaign-unsubscribe.component";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { WalletComponent } from "./components/pages/wallet/wallet.component";
import { SupportComponent } from "./components/pages/support/support.component";
import { ReferEarnComponent } from "./components/pages/refer-earn/refer-earn.component";
import { BillPaymentsComponent } from "./components/pages/bill-payments/bill-payments.component";
import { LegalComponent } from "./components/pages/legal/legal.component";
import { AccountComponent } from "./components/pages/account/account.component";
import { SettingComponent } from "./components/pages/settings/settings.component";

export const routes: Routes = [
  {
    path: "index",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "login",
    redirectTo: "auth",
    pathMatch: "full",
  },

  {
    path: "",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: IndexComponent,
      },
      {
        path: "transfers",
        loadChildren: () =>
          import(`./components/pages/transfers/transfers.module`).then(
            (m) => m.TransfersModule
          ),
        canActivate: [AfterLoginService],
      },
      {
        path: "recipients",
        loadChildren: () =>
          import(`./components/pages/recipients/recipients.module`).then(
            (m) => m.RecipientsModule
          ),
        canActivate: [AfterLoginService],
      },
      {
        path: "transfer",
        loadChildren: () =>
          import(`./components/pages/transfer/transfer.module`).then(
            (m) => m.TransferModule
          ),
        canActivate: [AfterLoginService],
      },
      {
        path: "wallet",
        loadChildren: () =>
          import(`./components/pages/wallet/wallet.module`).then(
            (m) => m.WalletModule
          ),
        component: WalletComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: "support",
        loadChildren: () =>
          import(`./components/pages/support/support.module`).then(
            (m) => m.SupportModule
          ),
        component: SupportComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: "refer-and-earn",
        loadChildren: () =>
          import(`./components/pages/refer-earn/refer-earn.module`).then(
            (m) => m.ReferEarnModule
          ),
        component: ReferEarnComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: "bills",
        loadChildren: () =>
          import(`./components/pages/bill-payments/bill-payments.module`).then(
            (m) => m.BillPaymentsModule
          ),
        component: BillPaymentsComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: "pay-bills",
        loadChildren: () =>
          import(`./components/pages/pay-bills/pay-bills.module`).then(
            (m) => m.PayBillsModule
          ),
        // component: PayBillsComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: "account",
        loadChildren: () =>
          import(`./components/pages/account/account.module`).then(
            (m) => m.AccountModule
          ),
        component: AccountComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: "limits",
        loadChildren: () =>
          import(`./components/pages/limits/limits.module`).then(
            (m) => m.LimitsModule
          ),
        canActivate: [AfterLoginService],
      },
      // {
      //     path: 'increase',
      //     loadChildren: () => import(`./components/pages/increase-limits/increase-limits.module`).then(m => m.IncreaseLimitsModule),
      //     canActivate: [AfterLoginService],
      // },
      {
        path: "settings",
        loadChildren: () =>
          import(`./components/pages/settings/settings.module`).then(
            (m) => m.SettingsModule
          ),
        component: SettingComponent,
        canActivate: [AfterLoginService],
      },
      // {
      //     path: 'price-chart',
      //     component: RateComparisionComponent,
      //     children: [
      //         {
      //             path: '',
      //             redirectTo: 'rate-comparision',
      //             pathMatch: 'full'
      //         },
      //         {
      //             path: '',
      //             component: RateComparisionComponent
      //         },
      //     ]
      // },

      {
        path: "campaign-unsubscribe",
        component: CampaignUnsubscribeComponent,
      },
      {
        path: "how-it-works",
        component: HowItWorksComponent,
      },
      {
        path: "agent-locations",
        component: AgentLocationsComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
      },
      {
        path: "help",
        component: FaqComponent,
      },
      {
        path: "email-confirmation",
        component: EmailConfirmationComponent,
      },
      {
        path: "legal",
        loadChildren: () =>
          import(`./components/pages/legal/legal.module`).then(
            (m) => m.LegalModule
          ),
        component: LegalComponent,
      },
    ],
  },

  {
    path: "auth",
    loadChildren: () =>
      import(`./components/pages/auth/auth.module`).then((m) => m.AuthModule),
    canActivate: [BeforeLoginService],
  },

  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "/404" },
];
