<ul>
  <li routerLinkActive="active">
    <a routerLink="/account"
      ><app-my-profile-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-my-profile-icon>
      My profile</a
    >
  </li>
  <li routerLinkActive="active">
    <a routerLink="/transfers"
      ><app-transfers-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-transfers-icon>
      Transfers</a
    >
  </li>
  <li routerLinkActive="active">
    <a routerLink="/recipients"
      ><app-recipients-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-recipients-icon>
      Recipients</a
    >
  </li>
  <li routerLinkActive="active">
    <a routerLink="/bills"
      ><app-bills-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-bills-icon>
      Bill payments</a
    >
  </li>
  <li routerLinkActive="active">
    <a routerLink="/limits"
      ><app-sending-limits-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-sending-limits-icon>
      Sending Limits</a
    >
  </li>
  <li routerLinkActive="active">
    <a routerLink="/refer-and-earn"
      ><app-refer-and-earn-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-refer-and-earn-icon>
      Refer & Earn</a
    >
  </li>

  <li routerLinkActive="active">
    <a routerLink="/support"
      ><app-support-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-support-icon>
      Support</a
    >
  </li>
  <li routerLinkActive="active">
    <a routerLink="/settings"
      ><app-settings-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-settings-icon>
      Setting</a
    >
  </li>
  <li routerLinkActive="active">
    <a href="#" (click)="logout($event)"
      ><app-logout-icon
        class="menu-svg-icon"
        width="24"
        height="24"
      ></app-logout-icon>
      Logout</a
    >
  </li>
</ul>
